.footer-links {
    @include media-breakpoint-down(md) { 
        margin-bottom: 0px !important;
    }
    li {
        @include media-breakpoint-down(md) { 
            &:last-child {
                margin-bottom: 0px !important;
            }
        }
        a {
            font-size: 1.25rem;
            color: $black !important;
            @include media-breakpoint-down(md) { 
                font-size: 1.10rem !important;
            }
            &:hover {
                text-decoration: underline !important;
            }
        }
    }
}

.footer-bottom {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    @include media-breakpoint-down(md) { 
        flex-flow: column-reverse;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .socialmedia-links {
        display: flex;
        flex-flow: row;
        align-items: center;
        margin: 0px;
        @include media-breakpoint-down(md) {
            margin-bottom: 15px !important;
        }
        li {
            margin-right: 15px;
            &:last-child{
                margin: 0px;
            }
        }
        .sm-icon {
            width: 36px;
            height: 36px;
            background-size: 100% !important;
            display: block;
            background-position: center center !important;
            @include media-breakpoint-down(md) { 
                width: 30px;
                height: 30px;
            }
            &.fb-icon {
                background: url("../images/fb-icon.svg") no-repeat;
            }
            &.twitter-icon {
                background: url("../images/twitter-icon.svg") no-repeat;
            }
            &.linkedin-icon {
                background: url("../images/linkedin-icon.svg") no-repeat;
            }
            &.instagram-icon {
                background: url("../images/instagram-icon.svg") no-repeat;
            }
        }
    }
}