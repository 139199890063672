.home-weareinfo {
   background: #d7dbe2; 
   padding: 50px 0;
   @include media-breakpoint-down(md) { 
    padding: 24px 0;
   }
   .weareinfo-col {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: center;
    @include media-breakpoint-down(md) { 
        padding-top: 15px;
    }
   }
}
.outline-btm {
    border-bottom: solid 3px $secondary;
}