.home-contact {
    background: $primary;
    padding: 50px;
    .contact-info {
        justify-content: center;
        p {
            color: $white;
        }
    }
    .col-contact-btn {
        text-align: right;
        align-items: center;
        display: inline-flex;
        justify-content: flex-end;
        @include media-breakpoint-down(md) { 
            justify-content: flex-start;
            margin-top: 15px;
        }
    }
    .lets-talk {
        line-height: 45px !important;
        margin: 0px !important;
    }
}