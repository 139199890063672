.home-services {
    @include media-breakpoint-down(md) { 
        padding-top: 15px;
    }
    .card {
        border: none;
        border-radius: 0px;
        img {
            border: none;
            border-radius: 0px;
        }
    }
    .card-body {
        background: $primary;
        min-height: 95px;
        max-height: 95px;
        display: flex;
        align-items: center;
        justify-content: space-between;        
        .service-title {
            color: $white;
            font-weight: 600;
            margin: 0;
            font-size: 1.5rem;
            line-height: 28px;
            max-width: 80%;
            &:hover {
                text-decoration: underline;
            }
        }
        .service-link {
            width: 100%;
            display: flex;
            flex-flow: row;
            justify-content: space-between;
            .link-icon {
                background: url("../images/arrow-round.svg") no-repeat;
                width: 42px;
                height: 42px;
                background-size: 100%;
                min-width: 42px;
                max-height: 42px;
            }
        }
    }
}
.has-bullet-icon {
    display: flex;
    flex-flow: row;
    align-items: center;
    line-height: 18px;
    i {
        font-size: 1.5rem;
        margin-right: 10px;
        color: $secondary !important;
    }
}
.services-page-card {
    .card {
        display: flex;
        flex-flow: column;
        width: 100%;
        border: 1px solid #ccc;
        border-radius: 8px;
        padding: 15px;
    }
    .card-body {
        background-color: transparent;
        max-height: inherit;
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-start;
        height: 200px;
        h5 {
            font-size: 20px;
            margin-bottom: 10px;
        }
    }
}

.design-services-col {
    display: flex;
    flex-flow: row;
    .icon-design-service {
        align-self: flex-start;
        margin: 7px 24px 0 0;
        max-width: 75px;
    }
}

.tech-logos {
    list-style:none;
    margin:0px;
    padding: 0px;
    display: flex;
    flex-flow: row wrap;
    li {
        max-width: 140px;
        max-height: 100px;
        margin-right: 15px;
        img {
            max-width: 100%;
        }
    }
}
