.carousel-item {
    height: 500px;
    background: $primary;
}
.caption-col {
    align-items: center;
    justify-content: flex-start;
    display: flex;
    .carousel-caption {
        position: inherit;
        text-align: left;
        @include media-breakpoint-down(md) { 
            margin-top: 60px;
            padding: 0px 40px !important;
         }
        .slider-title {
            font-weight: 700;
            margin: 0px;
            color: $white;
            span {
                color: $secondary;
            }
        }
        .slide-info {
            font-size: 1.25rem;
            margin-top: 20px;
        }
    }
}
.slideimg-col {
    padding: 50px 0;
}