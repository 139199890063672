/*****CURRENT JOBS *****/
.jobs-list {
    padding: 50px 0;
}
.recent-job-style2-item {
    background-color: #f4f7f7;
}
.recent-job-item {
    background-color: #f1f1f1;
    border-radius: 5px;
    margin-bottom: 30px;
    padding: 42px 40px 41px;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
}
.recent-job-item .company-info {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.recent-job-item .company-info .logo {
    margin: 0px;
}
.recent-job-item .company-info img {
    border-radius: 5px;
    min-width: 75px;
}
.recent-job-item .company-info .content {
    margin-left: 15px;
    margin-top: -1px;
}
.recent-job-item .company-info .name {
    color: #272a33;
    font-size: 20px;
    margin-bottom: 9px;
}
.recent-job-item .company-info .name a {
    color: #272a33;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
}
.recent-job-item .company-info .address {
    font-size: 15px;
    line-height: 1;
    color: #666;
}
.recent-job-item .main-content {
    margin: 12px 0 20px;
}
.recent-job-item .main-content .title {
    color: #272a33;
    font-size: 24px;
    margin-bottom: 12px;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
}
.recent-job-item .main-content .title a {
    color: #272a33;

}
.recent-job-item .main-content .work-type {
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    color: #03a84e;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 15px;
}
.recent-job-item .main-content .desc {
    font-size: 15px;
    line-height: 1.87;
    max-width: 234px;
}
.recent-job-item .recent-job-info {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: flex-start;
}
.recent-job-item .recent-job-info .mr-15 {
    margin-right: 15px;
}
.recent-job-item .recent-job-info .salary {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 12px;
}
.recent-job-item .recent-job-info .salary h4 {
    font-size: 22px;
    line-height: 1;
    margin-bottom: 0;
}
.recent-job-item .recent-job-info .salary p {
    bottom: 3px;
    font-size: 14px;
    line-height: 1;
    position: relative;
}