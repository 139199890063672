.main-wrap {
    padding: 100px 0 0 0;
}
.content-col {
    padding: 0px 30px;
    .ctnt-title {
        font-size: 1.40rem;
        line-height: 30px;
        font-weight: 600;
        color: $primary;
        margin: 0px;
    }
    .ctnt-normal {
        font-size: 1.20rem;
        font-weight: 500;

    }
}

.country-1 {
    ul {
        li {
            padding: 7.5px 0;
            display: inline-flex;
            flex-flow: row;
            line-height: 28px;
        }
    }
}

.section-bg-grey {
    background-color: #d7dbe2;
}
