.logo-size {
    svg {
        max-width: 15rem;
        @include media-breakpoint-down(md) { 
            max-width: 10rem !important;
        }
    }
}

.nav-link {
    color: $primary;
    font-weight: 700;
    margin-left : 0.50rem;
    margin-right: 0.50rem;
}

.nav-item {
    &.active {
        .nav-link {
            color: $orange !important;
        }
    }
}
.navbar {
    .container {
        @include media-breakpoint-down(md) { 
            padding: 0px 30px;
            margin: 0px;
            max-width: 100vw;
        }
    }
}