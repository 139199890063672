.btn-rounded {
    border-radius: 24px !important;
    padding-left: 28px !important;
    padding-right: 28px !important;
}

.btn-primary, .btn-secondary {
    color: $white !important;
}
.btn-has-arrow {
    display: inline-flex !important;
    align-items: center !important;
    .ri-arrow {
        background: url("../images/arrow-right.svg") no-repeat 5px 3px;
        width: 38px;
        height: 24px;
        display: inline-flex;
        background-size: 100%;        
    }
}