.breadcrumb-row {
    padding: 15px 0 5px;
    .breadcrumb-navs {
        display: inline-flex;
        flex-flow: row;
        align-items: center;
        a {
            font-size: 1.2rem;
            color: $black;
            text-decoration: none;
            font-weight: 500;
            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }
            &.active-breadcrumb {
                color: $secondary;
                &:hover {
                    text-decoration: none;
                    cursor: default;
                }
            }

        }
        .sprtr {
            margin: 0px 10px;
        }
    }
}