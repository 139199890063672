/* Custom font face */
@font-face {
    font-family: 'Gellix';
    src: url('../fonts/Gellix-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: auto;
}

@font-face {
    font-family: 'Gellix';
    src: url('../fonts/Gellix-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: auto;
}

@font-face {
    font-family: 'Gellix';
    src: url('../fonts/Gellix-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: auto;
}

@font-face {
    font-family: 'Gellix';
    src: url('../fonts/Gellix-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: auto;
}

@font-face {
    font-family: 'Gellix';
    src: url('../fonts/Gellix-Black.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}